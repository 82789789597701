
import { defineComponent } from "vue";
import * as Yup from "yup";
import ApiService from "@/core/services/ApiService";
import { apiEndpoint } from "@/mixin/apiEndpoint";
import Swal from "sweetalert2/dist/sweetalert2.min.js";
import moment from "moment";
import { VueCookieNext } from 'vue-cookie-next';
import MapAssessorInstitute from '@/layout/header/partials/assessor/mapAssessorInstituteDrawer.vue';

export default defineComponent({
  mixins: [apiEndpoint],
  name: "assessor-search",
  components: {
    //Datatable,
     MapAssessorInstitute,
  },
  data() {
    return {
      api_url: "",
      educationDetails: [] as any,
      professionalDetails: [] as any,
      certificates: [] as any,
      batchDetails: [] as any,
      assessor: {
        registration_number: "",
        nid: "",
        mobile: "",
        bcn: "",
      },
      moment:"" as any,
      assessorInfo: {} as any,
      entity_type_id: '',
      adminPermission: false,
      userInfo: {
        employee: {
          entity_organogram: [],
        } as any,
      },
      load: false,
      showAssessorInfo: false,
    };
  },
  async created() {
    this.api_url = this.VUE_APP_API_URL;
    this.moment=moment;
    this.userInfo = VueCookieNext.getCookie('_seip_user');
    let userRole =
      this.userInfo?.employee?.entity_organogram[0]?.entitytyperole?.role_title;
    if (userRole == 'Admin') {
      this.adminPermission = true;
    }
    this.entity_type_id = VueCookieNext.getCookie('_seip_entity_type');
  },
  methods: {
    async getAssessorInfo() {
      this.load = true;
      if (
        this.assessor.registration_number ||
        this.assessor.nid ||
        this.assessor.mobile ||
        this.assessor.bcn
      ) {
        await ApiService.get(
          "entity/assessor/search-assessor?unique_number=" +
            this.assessor.registration_number +
            "&nid=" +
            this.assessor.nid +
            "&bcn=" +
            this.assessor.bcn +
            "&mobile=" +
            this.assessor.mobile
        )
          .then((response) => {
            this.load = false;
            if (!response.data.data.assesor) {
              this.load = false;
              this.showAssessorInfo = false;
              Swal.fire({
                title: "Assessor Finding",
                text: "No Match",
                icon: "error",
                buttonsStyling: false,
                confirmButtonText: "close",
                customClass: {
                  confirmButton: "btn btn-danger",
                },
              });
            }
            this.assessorInfo = response.data.data["assesor"];
            //console.log( response.data.data.educational_details)
            this.educationDetails =
              response.data.data["assesor"].educational_details;
            this.professionalDetails =
              response.data.data["assesor"].professional_details;
            this.certificates = response.data.data["assesor"].certificate;
            this.batchDetails = response.data.data["batchTrack"];
            this.showAssessorInfo = true;
          })
          .catch(({ response }) => {
            this.load = false;
            console.log(response);
          });
      } else {
        this.load = false;
        Swal.fire({
          title: "Please Provide NID or BCN or Trainer Number or Mobile Number",
          text: "Empty Parameter",
          icon: "error",
          buttonsStyling: false,
          confirmButtonText: "close",
          customClass: {
            confirmButton: "btn btn-danger",
          },
        });
      }
    },
    mapassessorinstitute(id) {
      this.emitter.emit(
        'entity-add-map-assessor-Institute-data',
        id
      );
    },
  },
});
